/**
 * Created by chandru on 11/5/20.
 */
import pkg from '../../package.json';

export const  CONSTANT = {
  SIDEBAR_IDS: {
    ADD_ORDERS_SLIDER: 'add-dms-orders-modal',
    O2D_RIGHT_SIDEBAR: 'sidebar-right',
    MAINTENANCE_LOG_FORM: 'notification-event-to-add',
    HOLIDAY_MANAGEMENT_FORM: 'holiday-modal',
    STATIC_DATA_DYNAMIC_FORM: 'static-data-dynamic-form-modal',
    FACE_MATCH_SIDEBAR: 'sidebar-faceMatch',
    ATTENDANCE_MODAL_TO_ADD: 'attendance-modal-to-add',
    ROLE_ASSET_AUTH_MODAL: 'role-asset-auth-modal',
    DMS_RIGHT_SIDEBAR: 'dms-sidebar-right',
    MMD_ROUTE_PLAN_FORM: 'add-mmd-route-plan-modal',
    MMD_MANUAL_CONSIGNMENT_TRUCK_MODAL: 'add-dms-order-and-assign-truck-modal',
    MMD_VEHICLE_ASSIGNMENT_BY_ROUTE_PLAN: 'add-dms-order-and-assign-truck-on-route-plan-modal',
    MMD_VEHICLE_ASSIGNMENT_ON_CONSIGNMENT_CREATE: 'add-dms-order-and-assign-truck-on-consignment-create-modal',
    ADD_ASSET_TO_QUEUE_MODAL: 'add-asset-to-queue-modal',
    GROUP_CREATE_MODAL: 'group-create-modal',
    GROUP_EDIT_MODAL: 'group-edit-modal',
    KIOSK_SIDEBAR_RIGHT: 'kiosk-sidebar-right',
    ADD_LMD_ORDER_MODAL: 'add-lmd-order-modal',
    KIOSK_ADD_PRODUCT: 'kiosk-product-modal',
    RIGHT_SIDEBAR_TO_ADD: 'sidebar-right-to-add',
    AUTO_REGISTERED_FACE: 'autoRegisteredFace-sidebar',
    ACCOUNT_EDIT_MODAL: 'account-edit-modal',
    ACCOUNT_CREATE_MODAL: 'account-create-modal',
    LANGUAGE_SETTINGS_MODAL: 'language-settings-modal',
    ROLE_CREATE_MODAL: 'role-create-modal',
    ROLE_EDIT_MODAL: 'role-edit-modal',
    USER_EDIT_MODAL: 'user-edit-modal',
    USER_CREATE_MODAL: 'user-create-modal',
    VISITOR_CREATE_MODAL: 'visitor-create-modal',
    ADD_DMS_ORDER_MODAL: 'add-dms-order-modal',
    THING_TYPE_EDIT_MODAL: 'thing-type-edit-modal',
    NOTIFICATION_EVENT_TO_EDIT: 'notification-event-to-edit',
    ADD_WAREHOUSE_ORDER_MODAL: 'add-warehouse-order-modal',
    WAREHOUSE_RIGHT_SIDEBAR: 'warehouse-sidebar-right',
    WAREHOUSE_SHIPPING_SIDEBAR: 'warehouse-shipping-sidebar-right',
    WAREHOUSE_INVENTORY_SIDEBAR: 'warehouse-inventory-sidebar-right',
    KIOSK_SINGLE_PRODUCT_CREATION_SIDEBAR: 'kiosk-single-product-creation-sidebar',
    MULTIPLE_ASSETS_CREATION_SIDEBAR: 'multiple-assets-creation-sidebar',
    KIOSK_CATEGORY_CREATION_SIDEBAR: 'kiosk-category-creation-sidebar',
    ABILITY_CREATE_MODAL:'ability-create-modal',
    ABILITY_EDIT_MODAL:'ability-edit-modal',
    ABILITY_FORM_MANAGEMENT:'ability-form-management-sidebar',
    KAGENT_SIDEBAR: 'k-agent-sidebar',
    KIOSK_GUEST:'kiosk-guest',
    KIOSK_TABLE_FORM_MANAGEMENT:'kiosk-table-form-management'
  },
  SOCKET: {
    TOPICS : {
      ACTIVE_INSPECTION_DATA : 'activeInspectionData',
      HEALTH_MANAGEMENT_RESPONSE : 'healthManagementResponse',
      GEO_FENCE_ASSET_COUNT : 'geoFenceAssetCount',
      OBD_DEVICE_GPS_PUSH : 'obdDeviceGpsPush',

      GEO_FENCE_CHECK : 'geoFenceCheck',

      TRACKER_EVENT_LISTENER : 'trackerEventListener',
      TRACKER_EVENT_MAINTENANCE_BAY_DISTANCE_LISTENER : 'trackerEventMaintenanceBayDistanceListener',
      INSPECTION_ENDED : 'inspectionEnded',
      INSPECTION_STARTED : 'inspectionStarted',

      FACE_MATCH_DETECTED_ALL : 'faceMatchDetectedAll',
      FACE_DETECTED_ALL : 'faceDetectedAll',

      CREATE_NAME_SPACE : 'createNameSpace',

      CAMERA_LIVE_FEED : 'cameraLiveFeed',
      CAMERA_LIVE_FEED_STATUS : 'cameraLiveFeedStatus',
    },
    SERVER: {
      NAMESPACE: {
        LIVE_FEED : 'serverLiveStats',
      },
      TOPICS: {
        LIVE_CPU_STATS: 'serverLiveStatsCpu',
        LIVE_MEMORY_STATS: 'serverLiveStatsMemory',
      }
    },
    TRACKER: {
      NAMESPACE: {
        TRACKER_GPS : '/tracker/gps',
        TRACKER_MIN_GPS: '/tracker/min/gps',
        TRACKER_GEOFENCE: '/tracker/geofence',
        GEOFENCE : '/geoFence'
      },
      TOPICS: {
        OBD_DEVICE_GPS_PUSH : 'obdDeviceGpsPush',
        GEO_FENCE_ASSET_COUNT : 'geoFenceAssetCount',
        LIVE : 'live',
        GEO_FENCE_CHECK : 'geoFenceCheck',
      }
    },
    NOTIFICATION: {
      NAMESPACE: {
        NOTIFICATION: '/notification'
      },
      TOPICS: {
        PUSH_NOTIFICATION: 'pushNotification'
      }
    },
    SECURITY: {
      NAMESPACE: {
        SECURITY_FACE_DETECTED_LIVE: '/security/facematch/live'
      },
      TOPICS: {
        FACE_MATCH_DETECTED_ALL: 'faceMatchDetectedAll',
        FACE_DETECTED_ALL: 'faceDetectedAll'
      }
    },
    ATTENDANCE: {
      NAMESPACE: {
        ATTENDANCE_LIVE: '/attendance/live'
      },
      TOPICS: {
        LIVE: 'live',
        COUNT: 'count',
        UPDATE: 'update'
      }
    },
    KEY_MANAGEMENT: {
      NAMESPACE: {
        QUEUE: '/keyManagement/queue',
        ASSIGNMENT: '/keyManagement/assignment',
        KEY_ACCESED: '/keyManagement/key/accesed',
        KEY_EXPIRED: '/keyManagement/key/expired',
        KEY_ASSIGNED: '/keyManagement/key/assigned',
        KEY_ERROR: '/keyManagement/key/error',
      },
      TOPICS: {
        LIVE: 'live',
      }
    },
    DMS: {
      NAMESPACE: {
        QUEUE: '/deliveryManagement/queue',
        DELIVERY: '/deliveryManagement/delivery',
        DELIVERY_GROUP: '/deliveryManagement/deliveryGroup',
      },
      TOPICS: {
        LIVE: 'live',
        LIVE_STATUS: 'live_status'
      }
    },
    MMD: {
      NAMESPACE: {
        QUEUE: '/deliveryManagement/queue',
        DELIVERY: '/deliveryManagement/delivery'
      },
      TOPICS: {
        LIVE: 'live',
        LIVE_STATUS: 'live_status'
      }
    },
    LMD: {
      NAMESPACE: {
        QUEUE: '/deliveryManagement/queue',
        DELIVERY: '/deliveryManagement/delivery'
      },
      TOPICS: {
        LIVE: 'live',
        LIVE_STATUS: 'live_status'
      }
    },
    O2D: {
      NAMESPACE: {
        ORDER: '/orderToDelivery/order',
        ORDER_STATUS_UPDATE: '/orderToDelivery/order/statusUpdate',
        UPLOAD_DOCUMENTS:'/orderToDelivery/order/ai/uploadDocuments'
      },
      TOPICS: {
        LIVE: 'live',
        LIVE_STATUS: 'live_status'
      }
    },
    KIOSK: {
      NAMESPACE: {
        ORDER: '/orderToDelivery/order/kiosk',
      },
      TOPICS: {
        LIVE: 'live',
        LIVE_STATUS: 'live_status',
        INVENTORY_UPDATE:'inventoryUpdate'
      }
    },
    POS:{
      NAMESPACE: {
        POSSTORE: '/iotzen/posStore/',
        POSSETIINGS:'/kiosk/account/',
        POSPAYMENT:'/iotzen/payment/pos/',
        PRINTER:'/iotzen/pos/',
      },
      TOPICNAME:{
        POSSTORE:'productUpdate',
        POSSETTINGS:'settings',
        POSPAYMENT:'paymentUpdate',
        PRINTER:'printerStatusUpdate'
      },
      KEY: {
        POSSTORE:'kiosk-order-pos-store',
        POSSETTINGS:'kiosk-order-pos-setting',
        POSPAYMENT:'kiosk-order-pos-payment',
        PRINTER:'kiosk-order-pos-printer'
      }
    },
    WAREHOUSE: {
      NAMESPACE: {
        INVENTORY: '/iotzen/wms/inventory/updated',
      },
      TOPICS: {
        LIVE: 'live'
      }
    },
    USER_NOTIFICATIONS: {
      NAMESPACE: {
        NOTIFICATION: '/userNotifications'
      },
      TOPICS: {
        LIVE: 'live'
      }
    },
    INSPECTION_BAY: {
      NAMESPACE: {
        INSPECTION_BAY: '/inspectionBay'
      },
      TOPICS: {
        TRACKER_EVENT_LISTENER : 'trackerEventListener',
        ACTIVE_INSPECTION_DATA : 'activeInspectionData',
        TRACKER_EVENT_MAINTENANCE_BAY_DISTANCE_LISTENER : 'trackerEventMaintenanceBayDistanceListener',
        INSPECTION_ENDED : 'inspectionEnded',
        INSPECTION_STARTED : 'inspectionStarted',
      }
    },
    SERVICES: {
      NAMESPACE: {
        SERVICES: '/services'
      },
      TOPICS: {
        HEALTH_MANAGEMENT_RESPONSE : 'healthManagementResponse',
      }
    },
    CAMERA_LIVE:{
      NAMESPACE: {
        CAMERA: '/camera/live'
      },
      TOPICS: {
        CAMERA_LIVE_FEED : 'cameraLiveFeed',
        CAMERA_LIVE_FEED_STATUS : 'cameraLiveFeedStatus',
      }
    },
    KAGENT:{
      NAMESPACE:{
        HEALTH_CHECK:'/kAgent/healthCheck',
        KAGENT_COMMAND:'/kAgent/commandExecResp'
      },
      TOPICNAME:'live',
      KEY:'k-agent'
    }
  },
  ASSET_TYPES: {
    CAR: 'car',
    DRIVER: 'driver',
    BEACON: 'beacon',
    RF_ID: 'rfId',
    OBD_DEVICE: 'obdDevice',
    BEACON_GATEWAY: 'beaconGateway',
    RFID_GATEWAY: 'rfidGateway',
    PERSON: 'person',
    CAMERA: 'camera',
    DOOR: 'door',
    FINGER_PRINT_SENSOR: 'fingerPrintSensor',
    SERVER: 'server',
    NFC_CARD: 'nfcCard',
    LOCK: 'lock',
    DELIVERY_PERSON: 'deliveryPerson',
    MOBILE: 'mobile',
    ORDER: 'order',
    VEHICLE: 'vehicle',
    COLD_CHAIN_BOX: 'coldChainBox',
    CITY: 'city',
    LOCATION: 'location',
    SENSOR: 'sensor',
    LOGISTIC_PROVIDER: 'logisticProvider',
    PRODUCT: 'product',
    DELIVERY_CENTER: 'deliveryCenter',
    CATEGORY: 'category',
    ZONE: 'zones',
    VENDOR: 'vendor',
    SOCKET: {
        NAMESPACE: {
            UPDATE_ASSET_STATE: '/asset/statusChange',
        },
        TOPICS: {
            LIVE: 'live',
        }

    }

  },
  SERVICE_TYPES: {
    GENERAL: 'general'
  },
  CASL_LANDING_PAGES: {
    ASSET: 'Asset',
    GROUP: 'Group',
    INSPECTION: 'Inspection',
    SECURITY: 'Security',
    SERVICE: 'Service',
    SETTINGS: 'Settings',
    TRACKER: 'Tracker',
    DIGITAL_KEY_QUEUE: 'Queue',
    DIGITAL_KEY_MANAGE: 'Manage keys',
    O2D_ORDER: 'O2D Order',
    LMD_ORDER: 'LMD Order',
    LMD_DASHBOARD: 'LMD Dashboard',
    MMD_CONSIGNMENT: 'MMD Consignment',
    MMD_DASHBOARD: 'MMD Dashboard',
    O2D_DASHBOARD: 'O2D Dashboard',
    DMS_ORDER: 'DMS Order',
    DMS_DASHBOARD: 'DMS Dashboard',
    KIOSK_ORDER: 'Kiosk Order',
    KIOSK_STORE: 'Kiosk Store',
    KIOSK_TODAY: 'Kiosk Today',
    KIOSK_KDS: 'Kiosk Kds',
    KIOSK_DASHBOARD: 'Kiosk Dashboard',
    WAREHOUSE_DASHBOARD: 'Warehouse Dashboard',
    WAREHOUSE_TRACKER: 'Warehouse Tracker',
    WAREHOUSE_ANALYTICS: 'Warehouse Analytics',
    MMD_CONSIGNMENT_ADD: 'MMD Consignment Add'
  },
  CASL_ROUTING_LINKS: {
    ASSET: 'asset',
    GROUP: 'groups',
    INSPECTION: 'inspection',
    SECURITY: 'security/autoRegisteredFace',
    SERVICE: 'services',
    SETTINGS: 'config/userProfile',
    TRACKER: 'fleet',
    DIGITAL_KEY_QUEUE: 'digitalKey/queue/car',
    DIGITAL_KEY_MANAGE: 'digitalKey/manageKey/keyAssignment',
    O2D_ORDER: 'O2D/order',
    LMD_ORDER: 'LMD/order',
    LMD_DASHBOARD: 'LMD/dashboard',
    MMD_CONSIGNMENT: 'MMD/consignment',
    MMD_DASHBOARD: 'MMD/dashboard',
    O2D_DASHBOARD: 'O2D/dashboard',
    DMS_ORDER: 'LMD/dashboard',
    DMS_DASHBOARD: 'LMD/order',
    KIOSK_ORDER: 'KIOSK/order',
    KIOSK_STORE: 'KIOSK/store',
    KIOSK_GUEST: 'KIOSK/guest',
    KIOSK_TODAY: 'KIOSK/today',
    KIOSK_KDS: 'KIOSK/kds',
    KIOSK_DASHBOARD: 'KIOSK/dashboard',
    WAREHOUSE_DASHBOARD: 'warehouse/monitoring/dashboard',
    WAREHOUSE_TRACKER: 'warehouse/track',
    WAREHOUSE_ANALYTICS: 'warehouse/analytics/zoneAnalytics',
  },
  LOGISTIC_PROVIDER_NAMES: {
    ZENDMS: 'zenDms',
  },
  LOGISTIC_PROVIDER: {
    TYPE : {
      TRACKING: 'tracking',
      MANUALLY_TRACKING: 'manuallyTracking',
      CARRIER: 'carrier'
    }
  },
  DMS_MODULES: {
      MMD: {
        UNIQUE_ID: 'mmd-zenDms',
        MODULE: 'mmd'
      },

      LMD: {
        UNIQUE_ID: 'lmd-zenDms',
        MODULE: 'lmd'
      },

      FMD: {
        UNIQUE_ID: 'fmd-zenDms',
        MODULE: 'fmd'
      },
  },
  MODULES: {
    FLEET: {
      MODULE: 'fleet'
    },
    ATTENDANCE: {
      MODULE: 'attendance'
    },
    KEY_MANAGEMENT: {
      MODULE: 'digitalKey'
    },
    SECURITY : {
      MODULE: 'security'
    },
    WAREHOUSE: {
      MODULE: 'wms'
    },
    O2D:{
      MODULE:'o2d'
    },
    KIOSK:{
      MODULE:'kiosk'
    }
  },
  TIMEINTERVALS: {
    OFFLINEINMIN: 3
  },
  DELIVERY_STATUS: {
    COMPLETED: 'completed',
    PENDING: 'pending',
    RETURNING: 'returning',
    LABELS: {
      RETURNING_TO: 'Returning to',
      ROUNDTRIP: 'Round Trip',
      RETURNED: 'Returned',
      IN_PROGRESS: 'In Progress'
    },
  },
  REQUIRED_ORDER_FIELDS: {
    TABLE: 'orderId,created,customerDetails.firstName,customerDetails.lastName,customerDetails.location,customerDetails.firstName,customerDetails.mobileNumber,expectedDeliveryDateTime,expectedDeliveryDateTimeDetails,orderDetails.items.name,orderDetails.items.quantity,orderDetails.items.id,billAddress.phone,shippingAddress.phone,accountId,pickUpLocation.name,pickUpLocation.branchCode,pickUpLocation.city,pickUpCity.city,logisticProvider.name,logisticProvider._id,lpDetails,orderSource,ordertype,delivery,orderStatus,isAddedToQueue,orderCancelled,payment,paymentGateway,pickup,invoiceNumber,invoiceDate,shipmentDate,additionalFields,customerTrackingLink,customerCollabrationStatus.currentStatus,deliveryCenter,orderDetails.items.id,currentStatusDate',
    TIMELINE: 'timeLine,moduleType,assetType',
    FORM: 'orderId,orderDetails,customerDetails.firstName,customerDetails.lastName,customerDetails.emailId,customerDetails.mobileNumber,customerDetails.location,expectedDeliveryDateTime,expectedDeliveryDateTimeDetails,logisticProvider.uniqueId,logisticProvider.name,logisticProvider._id,additionalFields,' +
    'deliveryCenter,billAddress.first_name,billAddress.last_name,billAddress.emailId,billAddress.phone,billAddress.address1,billAddress.city,billAddress.zip,pickUpCity.city,pickUpCity._id,pickUpLocation.name,pickUpLocation._id,payment,invoiceNumber,invoiceDate,shipmentDate,lpDetails',
    MMD_FORM: 'orderId,expectedDeliveryDateTime,expectedDeliveryDateTimeDetails,' +
    'pickUpCity.city,pickUpCity._id,pickUpLocation.name,pickUpLocation._id,payment,deliveryCenter,orderDetails'
  },
  ASSET_PRIMARY_INCLUDED_FIELDS: {
    CITY: 'city,assetType,state,country,archived',
    LOCATION: 'name,city,branchCode,assetType,lat,lng,archived',
    LOGISTIC_PROVIDER: 'name,uniqueId,assetType,type,archived',
    PRODUCT: 'itemName,assetType,archived',
    DELIVERY_CENTER: 'name,assetType,businessName,branchCode,lat,lng,status,archived',
    VEHICLE: 'name,assetType,registrationNumber,vinNumber,mobileNo,lastlocation,currentLocation,gpsData.locationDetails,gpsData.location,gpsData.deviceId,archived',
    DELIVERY_PERSON: 'name,assetType,registrationNumber,vinNumber,mobileNo,lastlocation,currentLocation,gpsData.locationDetails,gpsData.location,gpsData.deviceId,archived',
  },
  ASSET_PRIMARY_EXCLUDED_FIELDS: {
    FIELD: 'assetsLinked,groups'
  },
  ASSET: {
    INITIAL_ASSET_LOAD_COUNT: 60,  // should be multiples of CURRENT_LIMIT_PER_PAGE
    CURRENT_LIMIT_PER_PAGE: 12,
    INCLUDED_TO_GET_ALL_DATA: ['city','location','deliveryCenter','category','vehicle','mobile','product','logisticProvider']
  },
  TABLE:{
    EXPECTED_DELIVERY_DEFAULT_SORT_FIELD_NAME: 'expectedDeliveryDateTimeDetails',
    EXPECTED_DELIVERY_SORT_FIELD_NAME: 'expectedDeliveryDateTime'
  },
  TABLE_SESSION_NAME:{
    O2D_ORDER_TAB : 'o2d_order_tab',
    O2D_PENDING_TAB : 'o2d_pending_tab',
    O2D_PROCESSING_TAB : 'o2d_processing_tab',
    O2D_DISPATCH_TAB : 'o2d_dispatch_tab',
    O2D_PROGRESS_TAB : 'o2d_progress_tab',
    MMD : {
      CONSIGNMENT: 'mmd_consignment_tab',
      CONSIGNMENT_ASSIGN: 'mmd_consignment_assign_tab',
      VEHICLE_ASSIGN: 'mmd_vehicle_assign_tab',
      VEHICLE_QUEUE: 'mmd_vehicle_queue_tab',
      CONSIGNMENT_QUEUE: 'mmd_order_queue_tab',
      ORDER: 'mmd_order_tab',
      ROUTE_PLAN:"mmd_route_plan",
      PROGRESS: 'mmd_progress_tab',
      DELIVERED: 'mmd_delivered_tab',
      DASHBOARD: 'mmd_dashboard_tab',
      ALL_SCHEDULE: 'mmd_all_schedule_tab',
      TODAY_SCHEDULE: 'mmd_today_schedule_tab',
      LOGS: 'mmd_delivery_planner_logs_tab',
    },
    LMD:{
      ORDER: 'lmd_order_tab',
      ORDER_QUEUE: 'lmd_order_queue_tab',
      ORDER_ASSIGN: 'lmd_order_assign_tab',
      AGENT_ASSIGN: 'lmd_agent_assign_tab',
      PROGRESS: 'lmd_progress_tab',
      DELIVERED: 'lmd_delivered_tab',
      DASHBOARD :'lmd_dashboard_tab'
    },
    KIOSK:{
      ORDER: 'kiosk_order_tab',
      TODAY: 'kiosk_today_order_tab',
      CDS: 'kiosk_cds_tab' ,
      KDS: 'kiosk_kds_tab',
      PRODUCT:'kiosk_product_management'
    },
    WAREHOUSE:{
      COMMON_INVENTORY: 'common_inventory',
      REQUISITION: 'warehouse_requisition_tab',
      SUPPLY: 'warehouse_supply_tab',
      FULFILLMENT: 'warehouse_fulfillment_tab',
      PROGRESS_TAB : 'warehouse_progress_tab',
      OUTBOUND_TAB : 'warehouse_outbound_tab',
      DASHBOARD: 'warehouse_dashboard_tab'

    },
   COMMUNICATION:{
     TABLE: 'communication_table'
   }
  },
  // SENTRY_KEY: 'https://2d9c47fa38254b948e35f0615b842e62@o219463.ingest.sentry.io/1363394'
   SENTRY_KEY: 'https://568f9257460747fa9eee1ce21d91f927@o1302573.ingest.sentry.io/6549681',

   KIOSK: {
    STORE_lOCATION: 'KIOSK_STORE_LOCATION',
    REQUIRED_ORDER_FIELDS:{
      TABLE: 'orderId,created,customerDetails.firstName,customerDetails.lastName,customerDetails.location,customerDetails.firstName,customerDetails.mobileNumber,expectedDeliveryDateTime,expectedDeliveryDateTimeDetails,orderDetails.items.name,orderDetails.items.quantity,orderDetails.items.instruction,billAddress.phone,shippingAddress.phone,accountId,pickUpLocation.name,pickUpLocation.city,pickUpCity.city,logisticProvider.name,orderSource,ordertype,delivery,orderStatus,isAddedToQueue,orderCancelled,payment,paymentGateway,pickup,tableData,dineType',
    }
   },
   WAREHOUSE: {
    STORE_lOCATION: 'WAREHOUSE_STORE_LOCATION',
    FACTORY_LOCATION: 'WAREHOUSE_FACTORY_LOCATION',
    PROGRESS_TAB : 'warehouse_progress_tab',
   },
   VERSION:pkg.version,
   SENTRY_AUTH_TOKEN: 'aa592018818548b2837a832f1df793f1f1f69a196f3a49f0849a813f2fd00dd2',


   GRAPH_KEYS : {
    O2D: {
        DELIVERY_BY_LOGISTIC_PROVIDER_ANALYTICS: "deliveryByLogisticProvider_o2d_analytics",
        TAT_OF_ORDER_ANALYTICS: "tatOfOrder_o2d_analytics",
        ORDERS_BY_CITY_ANALYTICS: "ordersByCity_o2d_analytics",
        ORDERS_BY_LOCATION_ANALYTICS: "ordersByLocation_o2d_analytics",
        ORDERS_BY_TYPE_ANALYTICS: "ordersByType_o2d_analytics",
        ORDER_VALUE_BY_LOGISTIC_PROVIDER_ANALYTICS: "orderValueByLogisticProvider_o2d_analytics",
        ORDER_VALUE_BY_CITY_ANALYTICS: "orderValueByCity_o2d_analytics",
        TIME_SLOTS_DASHBOARD: "timeSlots_o2d_dashboard",
        ASSIGNED_STATUS_DASHBOARD: "assignedStatus_o2d_dashboard",
        DELIVERY_STATUS_DASHBOARD: "deliveryStatus_o2d_dashboard",
        TAT_OF_ORDER_DASHBOARD: "tatOfOrder_o2d_dashboard",
        DELIVERY_TEMPERATURE_DASHBOARD: "deliveryTemperature_o2d_dashboard"
    },
    MMD: {
        CONSIGNMENTS_BY_TIME_SLOTS_ANALYTICS: "consignmentsByTimeSlots_mmd_analytics",
        CONSIGNMENTS_BY_DELIVERY_STATUS_ANALYTICS: "consignmentsByDeliveryStatus_mmd_analytics",
        DELIVERY_TEMPERATURE_ANALYTICS: "deliveryTemperature_mmd_analytics",
        TOTAL_DISTANCE_TRAVELLED_ANALYTICS: "totalDistanceTravelled_mmd_analytics",
        TEMPERATURE_ANALYTICS: "temperature_mmd_analytics",
        HUMIDITY_ANALYTICS: "humidity_mmd_analytics",
        SPEED_ANALYTICS: "speed_mmd_analytics",
        CONSIGNMENT_STATUS_DASHBOARD: "consignmentStatus_mmd_dashboard",
        IN_PROGRESS_ROUTE_PLANS_DASHBOARD: "inProgressRoutePlans_mmd_dashboard",
        ROUTE_PLANS_STATUS_DASHBOARD: "routePlansStatus_mmd_dashboard",
        ROUTE_PLANS_ASSIGNED_STATUS_DASHBOARD: "routePlansAssignedStatus_mmd_dashboard"
    },
    LMD: {
        COUNTS_BY_ORDER_SOURCE_ANALYTICS: "countsByOrderSource_lmd_analytics",
        ORDERS_BY_ASSIGNED_STATUS_ANALYTICS: "ordersByAssignedStatus_lmd_analytics",
        ORDERS_BY_DELIVERY_STATUS_ANALYTICS: "ordersByDeliveryStatus_lmd_analytics",
        ORDER_IN_QUEUE_ANALYTICS: "orderInQueue_lmd_analytics",
        DELIVERY_PERSONS_IN_QUEUE_ANALYTICS: "deliveryPersonsInQueue_lmd_analytics",
        RIDER_WISE_PERFORMANCE_ANALYTICS: "riderWisePerformance_lmd_analytics",
        ORDER_BY_AGENT_ANALYTICS: "orderByAgent_lmd_analytics",
        TAT_OF_ORDER_ANALYTICS: "tatOfOrder_lmd_analytics",
        DELIVERY_TEMPERATURE_ANALYTICS: "deliveryTemperature_lmd_analytics",
        TOTAL_DISTANCE_TRAVELLED_ANALYTICS: "totalDistanceTravelled_lmd_analytics",
        TEMPERATURE_ANALYTICS: "temperature_lmd_analytics",
        HUMIDITY_ANALYTICS: "humidity_lmd_analytics",
        SPEED_ANALYTICS: "speed_lmd_analytics",
        ORDERS_BY_TIME_SLOTS_DASHBOARD: "ordersByTimeSlots_lmd_dashboard",
        ORDERS_BY_ASSIGNED_STATUS_DASHBOARD: "ordersByAssignedStatus_lmd_dashboard",
        ORDERS_BY_DELIVERY_STATUS_DASHBOARD: "ordersByDeliveryStatus_lmd_dashboard",
        ORDER_IN_QUEUE_DASHBOARD: "orderInQueue_lmd_dashboard",
        DELIVERY_PERSONS_IN_QUEUE_DASHBOARD: "deliveryPersonsInQueue_lmd_dashboard",
        RIDER_WISE_PERFORMANCE_DASHBOARD: "riderWisePerformance_lmd_dashboard",
        DELIVERY_TEMPERATURE_DASHBOARD: "deliveryTemperature_lmd_dashboard",
        TAT_OF_ORDER_IN_PERCENTAGE_DASHBOARD: "tatOfOrderInPercentage_lmd_dashboard",
        TAT_OF_ORDER_DASHBOARD: "tatOfOrder_lmd_dashboard"
    },
    FLEET: {
        DAY_WISE_DISTANCE_IN_LAST_SEVEN_DAYS_ANALYTICS: "dayWiseDistanceInLastSevenDays_fleet_analytics",
        TOP_ACTIVE_VEHICLE_ANALYTICS: "topActiveVehicle_fleet_analytics",
        NUMBER_OF_VEHICLE_ANALYTICS: "numberOfVehicle_fleet_analytics",
        TOTAL_DISTANCE_TRAVELLED_ANALYTICS: "totalDistanceTravelled_fleet_analytics",
        TEMPERATURE_ANALYTICS: "temperature_fleet_analytics",
        HUMIDITY_ANALYTICS: "humidity_fleet_analytics",
        SPEED_ANALYTICS: "speed_fleet_analytics"
    },
    KIOSK: {
        SALES_BY_DINE_TYPE_DASHBOARD: "salesByDineType_kiosk_dashboard",
        TOTAL_ORDERS_DASHBOARD: "totalOrders_kiosk_dashboard",
        PAID_ORDERS_DASHBOARD: "paidOrders_kiosk_dashboard",
        DISCARDED_ORDERS_DASHBOARD: "discardedOrders_kiosk_dashboard",
        REFUNDED_ORDERS_DASHBOARD: "refundedOrders_kiosk_dashboard",
        TOTAL_SALES_DASHBOARD: "totalSales_kiosk_dashboard",
        TOP_PRODUCTS_DASHBOARD: "topProducs_kiosk_dashboard",
        SALES_BY_DINE_TYPE_ANALYTICS: "salesByDineType_kiosk_analytics",
        TOP_PRODUCTS_ANALYTICS: "topProducs_kiosk_analytics",
        DISCARDED_ORDERS_ANALYTICS:"discardedOrders_kiosk_analytics",
        REFUNDED_ORDERS_ANALYTICS: "refundedOrders_kiosk_analytics",
        PAID_ORDERS_ANALYTICS: "paidOrders_kiosk_analytics",
        TOTAL_ORDERS_ANALYTICS: "totalOrders_kiosk_analytics",
        TOTAL_SALES_ANALYTICS: "totalSales_kiosk_analytics",
    }
}

};
